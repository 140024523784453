<template>
  <div>
    <div class="row">
      <div class="col-xs-12">
        <page-header
          :title="'Coupons'"
          :sub-heading="true"
          :sub-header="'Dashboard'"
          :link-name="'Coupons'"
          :link-url="'/coupons'"
        >
          <ca-button :type="'ghost'" class="mr-2" @click.native="activeCoupons"
            >Active Coupons</ca-button
          >
          <ca-button @click.native="createCoupon">Create Coupon</ca-button>
        </page-header>

        <full-page-content-container>
          <ca-popup
            :header="'Delete Coupon'"
            :toggle="openDeletePopup"
            @open="confirmDelete"
            @close="cancelDelete"
          >
            <template v-slot:content>
              Are you sure you want to delete this coupon?
            </template>
            <template v-slot:actions>
              <ca-button @click.native="deleteCoupon">Yes</ca-button>
            </template>
          </ca-popup>

          <grid-table
            :column-defs="columnDefs"
            :row-data="rowData"
            v-if="rowData.length > 0"
          >
            <template v-slot:cell-renderer="slotProps">
             <div>
              <span
                class="edit-icon"
                @click="manageCoupon(slotProps.currentRowData.id)"
                ><img
                  :src="require('@/assets/images/icons/view.svg')"
                  alt="edit icon"
              /></span>
              <span class="delete-icon"
                  @click="confirmDelete(slotProps.currentRowData)"
                  ><img
                    :src="require('@/assets/images/icons/delete.svg')"
                    alt="delete icon"
                /></span>
              </div>
            </template>
          </grid-table>
          <empty-dataset v-else></empty-dataset>
        </full-page-content-container>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "../../layouts/components/fullPage/PageHeader";
import CaButton from "../../components/buttons/CaButton";
import FullPageContentContainer from "../../layouts/components/fullPage/FullPageContentContainer";
import GridTable from "../../components/tables/GridTable";
import gridTableParentMixin from "../../../mixins/gridTableParentMixin";
import axios from "../../../axios";
import CaPopup from "../../components/popup/CaPopup";
import EmptyDataset from "../../components/displays/EmptyDataset";
export default {
  mixins: [gridTableParentMixin],
  name: "Coupons",
  components: {
    EmptyDataset,
    GridTable,
    FullPageContentContainer,
    CaButton,
    PageHeader,
    CaPopup,
  },
  data() {
    return {
      columnDefs: [
        {
          headerName: "Code",
          field: "id",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Discount Price (%)",
          field: "percent_off",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Date Created",
          field: "created",
          type: "date",
          dateFormat: "timestamp",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Expiring Date",
          field: "redeem_by",
          type: "date",
          dateFormat: "timestamp",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Use Count",
          field: "times_redeemed",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Actions",
          field: "actions",
          hasCellRendererFramework: true,
        },
      ],
      rowData: [],
      openDeletePopup: false,
      currentcoupon: null,
      dataResourceUrl: `${this.$store.state.server.requestUri}/admin/coupons`,
    };
  },

  methods: {
    viewUser(userID) {
      this.$router.push({ name: "vu-edit-user", params: { id: userID } });
    },
    createCoupon() {
      this.$router.push({ name: "create-coupon" });
    },
    activeCoupons() {
      this.$router.push({ name: "active-coupons" });
    },

    getTableData(resourceUrl) {
      const loading = this.$vs.loading();
      axios
        .get(`${resourceUrl}`)
        .then((res) => {
          loading.close();
          const response = res.data;
          const success = response.success;
          if (success !== true) {
            this.$toast(response.message, {
              type: "error",
              position: "top-center",
            });
          }

          this.rowData = res.data.data.data;
          console.log(this.rowData);
        })
        .catch((err) => {
          this.$toast(err.response.data.message, {
            type: "error",
            position: "top-center",
          });
          loading.close();
          console.log(err.response.data);
        });
    },

    manageCoupon(couponId) {
      this.$router.push({
        path: `coupons/${couponId}/promo-codes`,
      });
    },
    cancelDelete() {
      this.openDeletePopup = false;
    },
    confirmDelete(popUpData = null) {
      if (popUpData !== null) {
        console.log(popUpData);
        this.currentcoupon = popUpData;
      }
      this.openDeletePopup = true;
    },

     deleteCoupon() {
      const loading = this.$vs.loading();
      axios
        .delete(
          `${this.$store.state.server.requestUri}/admin/coupons/${this.currentcoupon.id}`
        )
        .then((res) => {
          this.getTableData(`${this.dataResourceUrl}?${this.defaultQueryString}`);
          loading.close()
          this.cancelDelete();
          this.$toast(
            `Coupon with id ${this.currentcoupon.id} deleted successfully`,
            {
              position: "top-center",
              type: "success",
            }
          );
          console.log(res);
        })
        .catch((err) => {
          loading.close();
          this.cancelDelete();
          this.$toast(`An error occurred while deleting Coupon`, {
            position: "top-center",
            type: "error",
          });
          console.log(err.response);
        });
    },
 
  },

  created() {
    this.getTableData(`${this.dataResourceUrl}?${this.defaultQueryString}`);
    // this.rowData = coupons;
  },
};
</script>

<style scoped>
.edit-icon {
  margin-right: 0.8rem;
}

span:hover {
  cursor: pointer;
}
</style>
